.container {
    width: 100%;
}

.rtlDirection{
    direction: rtl;
}

.contentContainer {
    max-width: 60.938vw;
    margin: 0 auto;
    padding-top: 4.688vw;
    padding-bottom: 7.031vw;
}

.title{
    margin-bottom: 3.646vw;
}

.WHITE {
    background-color: var(--primary-bg-color);
    color: var(--primary-text-color);
}

.BLACK {
    background-color: var(--secondary-bg-color);
    color: var(--secondary-text-color-text-color);
}

.GRAY {
    background-color: var(--third-bg-color);
    color: var(--primary-text-color);
}

@media (max-width: 1024px) {
   .contentContainer{
       padding: 90px 45px 100px;
       max-width: none;
   }

}

@media (max-width: 768px) {
    .contentContainer{
        padding-top: 65px;
        padding-bottom: 65px;
    }
}

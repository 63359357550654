.container {
    margin: 0 auto;
    color: var(--primary-text-color);
}

.portrait{
    display: flex;
    align-items: center;
    margin-bottom: 3.385vw;
}

.mainImage{
    width:100%;
}

.mainImageWrapper{
    position: relative;
    width: 24.792vw;
    min-width: 24.792vw;
}

.title{
    position: absolute;
    font-size: 1.458vw;
    font-weight: 700;
    color: var(--secondary-text-color);
    left: 6vw;
    top: 8vw;
}

.bitcoinImage{
    width: 6.875vw;
    min-width: 6.875vw;
    margin-left: 0.781vw;
    height: 3.802vw;
}

.smileImage{
    width: 7.344vw;
    min-width: 7.344vw;
    height: 7.031vw;
    margin-left: 1.042vw;
    margin-right: 5.938vw;
}


.countryImage{
    width: 7.292vw;
    min-width: 7.292vw;
    margin-right: 0.521vw;
    margin-bottom: 0.521vw;
}

.countryWrapper{
    display: flex;
    align-items: center;
    position: relative;
}

.countryLabel{
    font-size: 1.146vw;
    font-weight: 700;
    max-width: 100px;
}

.text{
    font-size: .99vw;
    font-weight: 300;
}

.line:not(:last-child){
    margin: 0 0 1.042vw;
}

.arrow{
    position: absolute;
    left: -4.5vw;
    width: 4.093vw;
}

.arrow1{
    top: 55%;
}

.arrow3{
    bottom: 55%;
}

.rtlDirection{
    .title{
        left: 7vw;
        top: 10vw;
    }

    .countryImage{
        margin-left: 0.521vw;
        margin-right: 0;
    }

    .bitcoinImage{
        margin-right: 0.781vw;
        margin-left:0;
    }

    .smileImage{
        margin-right: 1.042vw;
        margin-left: 5.938vw;
    }

    .arrow{
        left: 14.0vw;
    }

    .arrow1{
        transform: rotate(240deg) ;
    }

    .arrow2{
        transform: rotate(180deg) ;
    }

    .arrow3{
        transform: rotate(120deg) ;
    }

    .bitcoinImage{
        display: none;
    }

    .countryLabel{
       max-width: 54px;
    }
}

@media (max-width: 1024px) {
    .text{
        font-size: 19.44px;
        font-weight: 300;
    }

    .line{
        margin: 0 0 20px !important;
    }

    .portrait{
        justify-content: center;
        flex-wrap: wrap;
    }

    .mainImageWrapper{
        width: 476px;
        min-width: 476px;
        margin-right: 100px;
        margin-left: 100px;
        margin-bottom: 40px;
    }

    .title{
        font-size: 28px;
        left: 101px;
        top: 165px;
    }

    .bitcoinImage{
        width: 132px;
        min-width: 132px;
        height: 73px;
    }

    .countryImage{
        width: 140px;
        min-width: 140px;
        margin-right: 10px;
    }

    .countryLabel{
        font-size: 22px;
        font-weight: 700;

    }

    .arrow{
        width: 109px;
        left: -120px;
    }

    .smileImage{
        width: 141px;
        min-width: 141px;
        height: 135px;
        margin-left: 20px;
        margin-right: 130px;
    }

    .rtlDirection{
        .title{
            left: 100px;
            top: 100px;
        }

        .mainImageWrapper{
            min-width: 476px;
            width: 100%;
            margin-right: 100px;
            margin-left: 100px;
            margin-bottom: 40px;
            display: flex;
            justify-content: center;
        }

        .mainImage{
            width: 476px;
        }

        .title{
            top: 50%;
            left: 54%;
            transform: translate(-50%, -50%);
            min-width: 0;
            white-space: nowrap;
        }

        .countryWrapper{
            margin-left: 71px;
            margin-right: 0;
        }



        .smileImage{
            margin-right: 0;
            margin-left: 130px;
        }

        .arrow{
            left: 207px;
        }
    }

}



@media (max-width: 768px) {
    .portrait{
        flex-direction: row;
        justify-content: center;
    }

    .text{
        font-size: 17px;
        font-weight: 300;
        opacity: 0.75;
    }

    .mainImage,
    .mainImageWrapper{
        width: 275px;
        height: 275px;
        margin: 0 0 30px 0;
        padding:0;
        min-width: 0;
    }

    .title{
        font-size: 17px;
        text-align: center;
        left: 60px;
        top: 101px;
    }

    .bitcoinImage{
        width: 92px;
        min-width: 92px;
        height: 51px;
        margin: 0 20px 0 0;
    }

    .smileImage{
        display: none;
    }

    .arrow{
        display: none;
    }

    .countryImage{
        width: 68px;
        margin-right: 10px;
        min-width: 0;
    }

    .countryLabel{
        font-size: 16px;
    }

    .rtlDirection{
        .title{
            left: 100px;
            top: 100px;
        }

        .mainImage,
        .mainImageWrapper{
            width: 275px;
            height: 275px;
            margin: 0 0 30px 0;
            padding:0;
            min-width: 0;
        }


        .title{
            top: 50%;
            left: 54%;
            transform: translate(-50%, -50%);
            min-width: 0;
            white-space: nowrap;
        }

        .countryWrapper{
            margin-left: 0;
            margin-right: 127px;
        }



        .smileImage{
            margin-right: 0;
            margin-left: 130px;
        }

        .arrow{
            left: 207px;
        }
    }

}

@media (max-width: 620px) {
    .rtlDirection{
        .portrait{
            flex-direction: column;
        }

        .countryWrapper{
            margin-right:0;

        }
    }

}





.wrapper{
    position: relative;
}

.container {
    margin: 0 auto;
    color: var(--primary-text-color);
    display: flex;
    justify-content: space-between;
}

.button{
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: transparent;
    color: var(--primary-text-color);
}

.iconWrapper{
    width: 5.208vw;
    height: 5.208vw;
    min-width: 5.208vw;
    margin-bottom: 0.625vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
    cursor: pointer;
    border-radius: 50%;
    transition: all 0.2s ease-in-out;
}

.icon{
    width: 2.813vw;
}

.label{
    cursor: pointer;
    width: 100%;
    text-align: center;
    font-size: 1.146vw;
    font-weight: 700;
}

@media (any-hover: hover) {
    .button:hover {

        .iconWrapper{
            transform: scale(1.05);
            opacity: 0.8;
        }

    }
}

.circle{
    position: absolute;
    bottom: -3.198vw;
    left: 85%;
    width: 54.375vw;
    height: 54.375vw;
}


.circleLeft{
    left: auto;
    right: 85%;
}

.rtlDirection{
    direction: rtl;
}

@media (max-width: 1024px) {
    .circle{
        display: none;
    }

    .iconWrapper{
        width: 100px;
        height: 100px;
        margin-bottom: 12px;
    }

    .icon{
        width: 54px;
    }

    .label{
    font-size: 22px;
    font-weight: 700;
}

}

@media (max-width: 768px){

    .container{
        max-width: 440px;
        margin: 0 auto;
    }

    .iconWrapper{
        width: 70px;
        height: 70px;
    }

    .icon{
        width: 34px;
    }

    .label{
        font-size: 16px;
    }
}
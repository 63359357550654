@font-face {
    src: url('./Comfortaa-Light.ttf');
    font-family: 'Comfortaa';
    font-weight: 300;
}

@font-face {
    src: url('./Comfortaa-Regular.ttf');
    font-family: 'Comfortaa';
    font-weight: 400;
}

@font-face {
    src: url('./Comfortaa-Medium.ttf');
    font-family: 'Comfortaa';
    font-weight: 500;
}

@font-face {
    src: url('./Comfortaa-Bold.ttf');
    font-family: 'Comfortaa';
    font-weight: 700;
}
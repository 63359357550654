.container {
    margin: -1.903vw auto 0 auto;
    padding-left: 13.021vw;
    position: relative;
    display: flex;
    color: var(--secondary-text-color);
}

.content {
    display: flex;
    margin-top: -4.323vw;
    padding-left: 13.021vw;
}

.anchor {
    border-bottom: 1px solid #fff;
    display: inline-block;
    position: relative;
    transition: border-bottom-color 0.1s ease-in-out;
    
    &:hover {
        border-bottom-color: transparent;
    }
}

.links {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
    display: flex;
    flex-direction: column;
    
    a {
        width: fit-content;
    }
}

.info {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
}

.anchor {
    border-bottom: 1px solid #fff;
    display: inline-block;
    position: relative;
    color: #fff;
    transition: border-bottom-color 0.1s ease-in-out;
    
    &:hover {
        border-bottom-color: transparent;
    }
}

.link {
    font-size: 1.146vw;
    margin-bottom: 1.563vw;
    font-weight: 700;
}

.subtitle {
    position: relative;
    font-size: 1.146vw;
    margin-bottom: 1.563vw;
}

.value {
    font-size: .99vw;
    margin-bottom: 1.875vw;
}

.text {
    font-size: .99vw;
    line-height: 1.354vw;
    font-family: var(--font-family-secondary);
    font-weight: 300;
}

.image {
    position: absolute;
    width: 6.979vw;
    height: 6.979vw;
    left: -0.76vw;
    top: 3.125vw;
}

.title {
    margin-bottom: 0;
}

.rtlDirection {
    padding-right: 13.021vw;
    padding-left: 0;
    
    .image {
        position: absolute;
        width: 6.979vw;
        height: 6.979vw;
        left: auto;
        right: 0.01vw;
        top: 3.125vw;
    }
    
}

@media (max-width: 1024px) {
    .container {
        flex-direction: column;
        margin-top: 0;
        padding-left: 0;
        
    }
    
    .image {
        width: 134px;
        height: 134px;
        left: 307px;
        top: -82px;
    }
    
    .rtlDirection {
        padding-right: 0;
        
        .image {
            width: 134px;
            height: 134px;
            right: 307px;
            top: -82px;
        }
    }
    
    .links {
        max-width: none;
        margin-top: 60px;
        display: flex;
        flex-direction: column;
        
        a {
            width: fit-content;
        }
    }
    
    .info,
    .link,
    .subtitle {
        max-width: none;
        font-size: 22px;
        font-weight: 700;
        margin-bottom: 30px;
    }
    
    .value {
        font-size: 19px;
        font-weight: 300;
        margin-bottom: 36px;
    }
    
    .text {
        max-width: none;
        font-size: 19px;
        font-weight: 300;
        line-height: 26px;
    }
}

@media (max-width: 768px) {
    .rtlDirection {
        padding-right: 0;
        
        .image {
            width: 134px;
            height: 134px;
            top: 15px;
            right: 50%;
            transform: translateX(50%);
        }
        
        .links {
            margin-top: 230px;
            justify-content: center;
            align-items: center;
        }
    }
    
    .image {
        position: static;
        width: 110px;
        height: 110px;
        margin: 30px auto 0;
    }
    
    .container {
        text-align: center;
        padding: 0;
    }
    
    .link,
    .subtitle {
        font-size: 16px;
        margin-bottom: 20px;
    }
    
    .links {
        justify-content: center;
        align-items: center;
    }
    
    .info {
        margin-bottom: 0;
    }
    
    .value {
        max-width: 100%;
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    
    .text {
        font-size: 13px;
        line-height: 22px;
        margin-bottom: 0;
    }
}


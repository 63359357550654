@font-face {
    src: url('./gothampro_light.ttf');
    font-family: 'Gotham Pro';
    font-weight: 300;
}

@font-face {
    src: url('./gothampro.ttf');
    font-family: 'Gotham Pro';
    font-weight: 400;
}

@font-face {
    src: url('./gothampro_medium.ttf');
    font-family: 'Gotham Pro';
    font-weight: 500;
}

@font-face {
    src: url('./gothampro_bold.ttf');
    font-family: 'Gotham Pro';
    font-weight: 700;
}
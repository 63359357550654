.wrapper {
    padding-bottom: 0;
}

.container {
    margin: 4.167vw auto 0 auto;
    color: var(--secondary-text-color);
    display: flex;
    flex-wrap: wrap;
}

.listItem {
    position: relative;
    padding-left: 3.385vw;
    padding-right: 3.385vw;
    margin-bottom: 5.469vw;
    flex: 0 0 50%;
    max-width: 50%;
    
}

.question {
    font-size: 1.458vw;
    margin-bottom: 1.25vw;
    font-weight: 700;
    position: relative;
    z-index: 5;
    white-space: nowrap;
}

.answer {
    font-size: .99vw;
    position: relative;
    z-index: 5;
    opacity: 0.85;
    font-weight: 300;
}

.number {
    position: absolute;
    pointer-events: none;
    font-size: 13.021vw;
    line-height: 10.417vw;
    left: 1.052vw;
    top: -0.948vw;
    color: #292929;
    font-family: var(--font-family-secondary);
}

.rtlDirection {
    direction: rtl;
    
    .number {
        left: auto;
        right: 1.052vw;
        top: -0.948vw;
    }
}

@media (max-width: 1024px) {
    .container {
        flex-direction: column;
        margin-top: 80px;
    }
    
    .listItem {
        max-width: none;
        margin-bottom: 105px;
        padding: 0;
    }
    
    .question {
        font-size: 28px;
        font-weight: 700;
        margin-bottom: 24px;
    }
    
    .answer {
        font-size: 19.44px;
        font-weight: 300;
    }
    
    .number {
        font-size: 250px;
        font-weight: 700;
        line-height: 200px;
        left: -47px;
        top: 1px;
    }
    
}

@media (max-width: 768px) {
    .container {
        margin-bottom: 50px;
    }
    
    .listItem {
        margin-bottom: 60px;
    }
    
    .question {
        font-size: 18px;
        margin-bottom: 19px;
        text-align: center;
    }
    
    .answer {
        font-size: 17px;
        text-align: center;
    }
    
    .number {
        font-size: 120px;
        line-height: 100px;
        left: 3px;
        top: -27px;
    }
}

@media (max-width: 320px) {
    .container {
        flex-wrap: inherit;
    }
    
    .question {
        white-space: normal;
    }
}
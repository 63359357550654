.input {
    height: 54px;
    border-radius: 27px;
    padding: 0 22px;
    border: 1px solid rgba(0, 0, 0, .65);
    font-size: 19px;
    width: 100%;
    display: block;
    font-family: 'Gotham Pro', sans-serif;
    font-weight: 300
}

.input::-webkit-input-placeholder {
    color: #939393
}

.input:-moz-placeholder, .input::-moz-placeholder {
    color: #939393
}

.input:-ms-input-placeholder {
    color: #939393
}

@media screen and (min-aspect-ratio: 8/5) and (min-width: 1024px) {
    .input {
        height: 5vh;
        border-radius: 2.5vh;
        padding: 0 2.037vh;
        font-size: 1.759vh
    }
}

@media screen and (min-width: 1024px) and (max-aspect-ratio: 8/5) {
    .input {
        height: 2.813vw;
        border-radius: 1.406vw;
        padding: 0 1.146vw;
        font-size: .99vw
    }
}

@media screen and (max-width: 767px) {
    .input {
        height: 48px;
        border-radius: 24px;
        padding: 0 18px;
        font-size: 16px
    }
}

@media screen and (max-width: 767px) and (orientation: landscape) {
    .input {
        height: 46px;
        border-radius: 22px;
        padding: 0 18px;
        font-size: 15px
    }
}